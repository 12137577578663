<template>
    <div>
		<AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>
		<AppEmptyStateComponent message="There are no facilities added yet."
								v-if="!stores.length && !loadingSpinner"/>

        <div class="stores">

            <div
                class="row"
                v-for="(store, index) in filteredStores"
                :key="index + '_store'"
                @click="goToSeeDetails(store.id)">

                <div class="flex-box-4 figtree-semi-bold-alto-14px">
                    <img v-if="store.image_url" class="facility-img" :src="store.image_url" alt="store-image"/>
			        <img v-else class="facility-img" src="@/assets/images/storeDefaultImg.png" alt="account-image">
                </div>

                <div class="flex-box-25 figtree-semi-bold-alto-14px">
                    {{store.name}}
                </div>

                <div class="flex-box-28 mail-over-flow figtree-semi-bold-alto-14px">
                    {{store.email ? store.email : '---------'}}
                </div>

                <div class="flex-box-15 figtree-semi-bold-alto-14px">
	                {{store.code ? store.code : '-'}}
                </div>

                <div class="flex-box-20 remove-at-mobile figtree-semi-bold-alto-14px">
                    {{store.moreInfo}}
                </div>
            
                <div class="flex-box-4"
                     id="edit-btn"
                     @click.stop="openEditStoreModal(store.id)">
                    <md-icon>edit</md-icon>
                </div>
                <div v-if="stores.length > 1" class="flex-box-4"
                     @click.stop="showConfirmDialog(store)">
                    <md-icon>delete_forever</md-icon>
                </div>
                 
            </div>

        </div>

        <CreateStoreDialog :showDialog.sync="showDialog" :key="dialogKey"
                           :disableCloseProp = "isCloseDisable"
                           :store-prop="store"
						   @addNewStore="pushNewStore"/>

      <md-dialog :md-active.sync="showContactUsDialog" :md-click-outside-to-close="true">
        <md-dialog-title>
          Info
        </md-dialog-title>
        <md-dialog-content>
          <md-dialog-prompt>You can add up to 9 Facilities to your account, to add more than 9 facilities please contact
            us email : <a href="mailto:contactus@reflex-radio.com">contactus@reflex-radio.com</a>
          </md-dialog-prompt>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="dialog__btn"
                     @click=close>
            Ok
          </md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="confirmDeleteDialog" :md-click-outside-to-close="true">
        <md-dialog-title>
          Info
        </md-dialog-title>
        <md-dialog-content>
          <p>If you delete this facility, you will delete all the Blueprints and playlists associated with this facility.
            Are you sure you want to delete this facility?
          </p>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="dialog__btn dialog__btn--danger"
                     @click="confirmDeleteDialog=false">
            Cancel
          </md-button>
          <md-button class="md-primary dialog__btn md-raised md-theme-light"
                     :disabled = "buttonClicked" @click=removeStore(storeId)>
            Delete
          </md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="confirmAppleDeleteDialog" :md-click-outside-to-close="true">
        <md-dialog-title>
          Info
        </md-dialog-title>
        <md-dialog-content>
                <p>You should delete this facility from your IOS device as you need to cancel the Apple subscription first!
                </p>
            </md-dialog-content>
        <md-dialog-actions>
          <md-button class="dialog__btn dialog__btn--danger"
                     @click="confirmAppleDeleteDialog=false">
            Cancel
          </md-button>
        </md-dialog-actions>
      </md-dialog>

    </div>
</template>

<script>
    import CreateStoreDialog from "../../components/Store/CreateStoreDialog.vue"
import accountService from "../../services/account.service";
    import StoreService from "../../services/store.service"
    import {DeepFilterMixin} from '@/mixins/DeepFilterMixin';
    import {mapGetters} from "vuex";

    export default {
        name: 'StoresDashboardStores',
        mixins: [DeepFilterMixin],

        data: () => ({
          stores: [],
          isCloseDisable: false,
          loadingSpinner: true,
          filterUserString: '',
          showDialog: false,
          showContactUsDialog: false,
          store: null,
          dialogKey: 0,
          confirmDeleteDialog: false,
          confirmAppleDeleteDialog: false,
          storeId: null,
          buttonClicked: false,
          is_apple_user:false
        }),
        components: {
            CreateStoreDialog
        },

        computed: {

            filteredStores() {

                return this.stores.filter(x => this.filterFunction(x, this.filterUserString));
            },

            ...mapGetters(
                {
                    showTourGuide: 'getIsTourGuideActive',
                    currentUser: 'auth/getCurrentUser',
                },
            )
        },

        methods: {

            goNextStepIfTourGuideActive() {

                this.$nextTick(() => {

                    this.$tours['myTour'].nextStep();
                })
            },

            setPageName() {

                this.$store.dispatch('changePageName', { pageNameString: 'Facility'});
            },

            goToSeeDetails(id) {

                this.$router.push('/stores/'  + id);
            },

            openCreateStoreModal() {
              if (this.stores.length >= 9) {
                this.showContactUsDialog = true;
              } else {
                this.dialogKey++;
                this.store = null;
                this.showDialog = true;

                if (this.showTourGuide) {
                  this.goNextStepIfTourGuideActive();
                }
              }
            },

            close(){
            this.showContactUsDialog = false;
            },

            openEditStoreModal (id) {

                this.dialogKey++;
                this.getCurrentStore(id);
                this.showDialog = true;
            },

            pushNewStore(newStore) {

                this.getAllStores();
            },

            removeStore(storeId) {
                this.buttonClicked = true;
                StoreService.deleteSingleStore(storeId)
                    .then(() => {

                        this.stores = this.stores.filter(x => x.id !== storeId);

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'The store was successfully deleted.',
                            duration: 4000,
                            mode: 'success'
                        });
                        this.confirmDeleteDialog = false;
                        this.buttonClicked = false;
                    })
                    .catch((err) => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: `${err.message}. Please, try again.`,
                            duration: 4000,
                            mode: 'fail'
                        });
                        this.buttonClicked = false;
                    });

            },

            getCurrentStore(id) {

                let store = this.stores.find(x => x.id === id)
                this.store = Object.assign({}, store);
            },

            getAllStores() {

                StoreService.getStores()
                    .then(res => {

						this.loadingSpinner = false;
                        this.stores = res.map(x => {
                            return {
                                ...x,
                                moreInfo: x.city + ' ' + x.street
                            }
                        });
                    })
                    .catch(() => {

                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Failed. Please, try again.',
                            duration: 8000,
                            mode: 'fail'
                        });
                    });
            },
          async showConfirmDialog(store) {
            if(store?.subscription?.is_apple_subscription){
                this.confirmAppleDeleteDialog = true;
            } else {
                this.storeId = store.id;
                this.confirmDeleteDialog = true;
            }
          },
          getCurrentAccount(id){
				id = (isNaN(id)) ? id.id : id ;

                return accountService.getOne(id)
                    .then((res) => {
                        this.is_apple_user = res.is_apple_user;
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackbarMessage', {message: 'Failed Please, try again.', duration: 8000, mode: 'fail'});
                    });
            },
        },

        created(){

            if (this.currentUser.account.has_store === false) {

                this.isCloseDisable = true;
                this.showDialog = true;
            }
            
            this.setPageName();
            this.getAllStores();
        },

        mounted() {

            if (this.showTourGuide) {

                this.goNextStepIfTourGuideActive();
            }
        }
    }
</script>

<style lang="scss" scoped>
@include dialogSettings();
    .content-header.content-header__stores {
        margin-bottom: 40px;
    }

    .input-field.input-field__stores-filter {
        padding-bottom: 0;
    }

    @include tableStyle(stores, store);

    .stores {

        .store {
            margin: 0;
	        font-size: 1.3rem;

	        @include respond(medium) {
		        text-align: center;
	        }

            &__image {
                background-color: #212121;
                margin-right: auto;

                img {
                    background-color: $color-white;
                }
            }

            &__data {
	            min-width: unset;
                line-height: 57px;

                @media only screen and (min-width: 1400px) {
                    &:not(:first-child) {
                        border-left: 1px solid $color-white;
                    }
                }

                @include respond(medium) {
                    line-height: normal;
                }
            }

	        &__remove-btn {

		        @include respond(medium) {
			        margin-left: unset;

			        .md-icon {
				        height: 50px;
				        width: 50px;
				        font-size: 30px !important;
			        }
		        }
	        }
        }
        .row{
	        display: flex;
	        justify-content: center;
	        text-align: left;
	        background: #262626;
	        border-radius: 4px 4px 0px 0px;
	        min-height: 56px;
            align-items: center;
	        padding-left: 10px;
	        padding-right: 10px;
            margin-bottom: 1px;
	        gap: 10px;
            cursor: pointer;
        }
        .row:hover{
	        background: #636262;

        }
        .flex-box-28{
	        flex:28;
        }
        .flex-box-25{
	        flex:25;
        }
        .flex-box-15{
            flex:15;
        }
        .flex-box-20{
            flex:20;
        }
        .flex-box-5{
            flex:5;
        }
        .flex-box-3{
            flex:3;
        }
        .flex-box-4{
            flex:4;
        }
        .facility-img{
	        height: 36px;
	        width: 36px;
	        min-width: 36px;
	        border-radius: 50%;
        }
        .mail-over-flow{
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .remove-at-mobile{
            @media only screen and (max-width: 481px) {
                display: none;
            };
        }
    }
</style>
