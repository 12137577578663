<template>
    <div class="component-wrap">


        <AppHeaderComponent headline="Subscriptions" class="content-header__classes">
            <div class="md-layout content-header__actions">
                <md-button
                    class="md-layout-item md-raised md-primary md-raised md-theme-light addBtn md-xlarge-size-45 md-large-size-45 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
                    @click="exportSubscriptions()">Export excel
                </md-button>
            </div>
        </AppHeaderComponent>

        <!--        <div class="md-layout md-alignment-center-space-between" style="margin-bottom: 30px;">-->
        <!--            <div class="md-layout-item md-elevation-5 md-size-50" style="padding: 20px" v-if="subscription">-->
        <!--                <h3>Subscription Info</h3>-->
        <!--                <div class="subscription-info">-->
        <!--                    <p v-for="(store, index) in stores" v-if="store.subscription">{{store.name}} - {{store.subscription.subscription_plan.name}} - ${{store.subscription.subscription_plan.price}}/month</p>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <AppSpinnerComponent v-if="loadingSpinner" :loading="loadingSpinner"/>

        <AppEmptyStateComponent message="There are no subscriptions added yet." v-if="showNoResults && !loadingSpinner"/>

        <div class="subscriptions" v-if="!loadingSpinner && !showNoResults">
            <div class="subscription-header md-layout md-raised">
                <h2 class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">Store</h2>
                <h2 class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">Price</h2>
                <h2 class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">Status</h2>
                <h2 class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">Start Date</h2>
                <!-- <h2 class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">Cancel at</h2> -->
                <h2 class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">Next renewal</h2>
            </div>

            <div class="subscription md-raised md-layout"
             v-for="(subscription, index) in subscriptions"
                :key="index + '_singleClass'">

                <p class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">
                    {{subscription.store.name}}
                </p>

                <p class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">
                    ${{subscription.price}}
                </p>

                <p class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">
                    {{ subscription.status }}
                </p>
                <p class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">
                    {{ subscription.current_period_start }}
                </p>
                <!-- <p class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">
                    <span v-if="subscription.cancel_at">{{subscription.cancel_at }}</span>
                </p> -->

                <p class="subscription__data md-layout-item md-size-15 md-xsmall-size-50">
                    <span  v-if="subscription.active && subscription.current_period_end && !subscription.cancel_at_period_end">{{
                            subscription.current_period_end
                        }}</span>
                    <span v-else-if="subscription.active && subscription.cancel_at_period_end && !subscription.canceled_at" class="clickable-span"
                        @click.stop="extendSubscription(subscription.id, subscription.is_apple_subscription)">Renew</span>									
                    <span v-else class="clickable-span"
                    @click=showSubscription(subscription)>
                    Resubscribe
                    </span>
                </p>

                <div class="subscription__plan-modal-btn"
                    v-if="userRole <= 2 && subscription.active === true && subscription.cancel_at_period_end === false"
                    @click.stop="openModal(subscription.id,subscription.is_apple_subscription)">
                    Cancel
                    <md-icon>cancel_presentation</md-icon>
                </div>
            </div>
        </div>

        <CancelSubscriptionModal v-if="showDialog" :showDialog.sync="showDialog" :key="dialogKey"
            :subscription-prop="subscriptionToCancel" @refreshList="getSubscriptions" />

        <md-dialog :md-active.sync="showAppleUserCancelSubAlertDialog" :md-click-outside-to-close="true">
            <md-dialog-title>
                Info
            </md-dialog-title>
            <md-dialog-content>
                <p>To update your subscription, please go to the <b>"Subscriptions" </b> section in your iOS device settings or manage your subscription directly through the mobile app.</p>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary dialog__btn md-raised md-theme-light" @click="showAppleUserCancelSubAlertDialog = false">
                    Ok
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog :md-active.sync="showSubscriptionDialog" :md-click-outside-to-close="true">
            <md-dialog-title>
                Resubscribe
            </md-dialog-title>
            <md-dialog-content>
                <AppInputComponent
                    class="create-form__field"
                    controlType="select"
                    required
                    placeholder="Choose a card"
                    v-model="selectedCardId"
                    :error="!selectedCardId"
                    errorText="Card is required"
                >
                <option
                    v-for="(card, index) in cards" :key="index" :value="card.id">
                    {{ card.brand }} ***{{ card.last4 }}
                </option>
                </AppInputComponent>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button class="dialog__btn dialog__btn--danger"
                        @click="showSubscriptionDialog = false">
                Cancel
                </md-button>
                <md-button :disabled="!selectedCardId" class="md-primary md-raised dialog__btn md-theme-light"
                        @click=resubscribe>
                Resubscribe
                </md-button>
            </md-dialog-actions>
        </md-dialog>

        <!--        <PlanModalDialog :showDialog.sync="showDialog"-->
        <!--                         :key="dialogKey"-->
        <!--                         :store-prop="store"-->
        <!--                         :subscription-plans="subscriptionPlans"-->
        <!--                         :subscriptionItems="subscriptionItems"-->
        <!--                         @addSubscriptionItem="addSubscriptionItem"/>-->
    </div>
</template>

<script>
    // import PlanModalDialog from "../../components/Subscription/PlanDialogModal.vue"
    import CancelSubscriptionModal from "../../components/Subscription/CancelSubscriptionModal"
    import SubscriptionService from '@/services/subscription.service'
    import {mapGetters} from "vuex";
    import cardService from "../../services/card.service";
    // import {Card, createPaymentMethod} from 'vue-stripe-elements-plus'

    export default {
        name: 'Subscriptions',
        data: () => ({
            subscriptions: [],
            subscriptionPlans: [],
            subscriptionItems: [],
            subscriptionToCancel: null,
            loadingSpinner: true,
            showDialog: false,
            showNoResults: false,
            dialogKey: 0,
            store: null,
            stripeOptions: {
                style: {
                    base: {
                        iconColor: '#c4f0ff',
                        color: '#fff',
                        fontWeight: 500,
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                            color: '#fce883',
                        },
                        '::placeholder': {
                            color: '#87BBFD',
                        },
                    },
                    invalid: {
                        iconColor: '#FFC7EE',
                        color: '#FFC7EE',
                    },
                }
            },
            complete: false,
            showAppleUserCancelSubAlertDialog: false,
            showAppleUserCancelSubAlertDialogKey: 0,
            showSubscriptionDialog: false,
            selectedCardId: null,
            cards: [],
            selectedSubscription: null
        }),

        components: {
            // PlanModalDialog,
            // Card,
            CancelSubscriptionModal
        },

        computed: {

            ...mapGetters(
                {
                  userRole: 'auth/currentUserRole',
                },
            ),
        },

        methods: {

            setPageName() {

                this.$store.dispatch('changePageName', {pageNameString: 'Subscriptions'});
            },

            getCurrentSubscription(id) {

                return this.subscriptions.find(x => x.id === id)
            },

            openModal(subscriptionId, isAppleSubscription) {
                if (isAppleSubscription){
                    console.log("isAppleSubscription: " ,isAppleSubscription)
                    this.showAppleUserCancelSubAlertDialogKey++;
                    this.showAppleUserCancelSubAlertDialog = true;
                }
                else{
                    this.subscriptionToCancel = this.getCurrentSubscription(subscriptionId);
                    this.dialogKey++;
                    this.showDialog = true;
                }
            },
            extendSubscription(id, isAppleSubscription) {
			    if(!isAppleSubscription){
			    	return SubscriptionService.extend(id)
			    		.then((res) => {
			    			this.$store.dispatch('showSnackbarMessage', {
			    			message: 'You subscription is updated successfully.',
			    			duration: 4000,
			    			mode: 'success'
			    		  });
			    			this.getSubscriptions();
			    		}).catch((error) => {
			    			console.log(error)
			    			this.$store.dispatch('showSnackbarMessage', {
			    				message: error.message,
			    				mode: 'fail'
			    			});
			    		});
			    }
			    else {
                    this.showAppleUserCancelSubAlertDialogKey++;
                    this.showAppleUserCancelSubAlertDialog = true;
			    }
            },
            resubscribe(){
                SubscriptionService.resubscribe(this.selectedSubscription.id,this.selectedCardId)
                    .then(() => {
                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'You subscription is updated successfully.',
                        duration: 4000,
                        mode: 'success'
                    });
                    this.getSubscriptions();
                    })
                    .catch((error) => {

                    console.log(error)
                    this.$store.dispatch('showSnackbarMessage', {
                        message: error.message,
                        mode: 'fail'
                    });
                    });
                this.showSubscriptionDialog = false;
      	    },
            getCards() {
			return cardService.getAllCards()
				.then(data => {
					this.cards = data;
				})
				.catch(error => {
					this.$store.dispatch('showSnackbarMessage', {
						message: error.message,
						mode: 'fail'
					});
				})
			},
            showSubscription(subscription){
                if (subscription.is_apple_subscription){
                    this.showAppleUserCancelSubAlertDialogKey++;
                    this.showAppleUserCancelSubAlertDialog = true;
                }
                else {
                    this.selectedSubscription = subscription;
                    this.showSubscriptionDialog = true;
                }
            },
            getSubscriptions() {

                return SubscriptionService.get()
                    .then((data) => {

                        this.loadingSpinner = false
                        this.subscriptions = data;
                    })
                    .catch((error) => {

                        console.log(error)
                        this.$store.dispatch('showSnackbarMessage', {
                            message: error.message,
                            mode: 'fail'
                        });
                    });
            },

            async fetchData() {

                await this.getSubscriptions();
                await this.getCards();
                if (!this.subscriptions.length) {

                    this.showNoResults = true;
                }
            },

			exportSubscriptions() {

				SubscriptionService.exportSubscriptions()
					.then((response) => {

						window.open(response);
					});
			}
        },

        created() {

            this.fetchData();
            this.setPageName();
        }
    }
</script>

<style lang="scss" scoped>

	.content-header__actions {
		width: 38%;
	}

    .content-header.content-header__subscriptions {
        margin-bottom: 40px;
    }

    .input-field.input-field__subscriptions-filter {
        padding-bottom: 0;
    }

    .stripe-card {
        margin: 20px;
    }

    @include tableStyle(subscriptions, subscription);

    .subscriptions {

        .subscription-header {
            color: $color-white;
            font-size: 1.8rem;
            text-align: left;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            padding: 0 32px 0 0;

            transition: all .2s ease;
        }

        .subscription {
            background-color: #212121;
            &__data {
                min-width: 18%;
                padding-left: 3.2rem;
                line-height: 57px;
            }

            &__plan-modal-btn {
                margin-left: auto;

                .md-icon {

                    &:hover {
                        background-color: #212121!important;
                        border-radius: 50%;
                        // padding: 18px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
	.clickable-span{
        cursor: pointer;
	    background-color: #bb6bd9;
	    padding: 8px 10px;
	    border-radius: 3px 3px 3px;
	    color: #FFFFFF;
	    font-size: 1.6rem;
    }
    .subscription-info {
        margin-top: 20px;
        margin-bottom: 12px;
        color: white;
    }
</style>
