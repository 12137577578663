<template>
  <div v-if="!authCode">
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">

      <md-dialog-content>
        <p>Please link your Spotify or Apple Music account to continue
        </p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class='md-primary dialog__btn md-raised md-theme-light centerize' @click='authorize'>
          <img class="music-icon" src="@/assets/images/AppleIcon.png" alt="fullscreen"/>
          Link Apple
        </md-button>
        <md-button class='md-primary dialog__btn md-raised md-theme-light centerize'   @click='authorizeSpotify'>
          <img class="music-icon" src="@/assets/images/Spotify_Primary_Logo_RGB_Black.png"  alt="fullscreen"/>
          Link Spotify
        </md-button>
        <md-button class="dialog__btn dialog__btn--danger centerize" @click=logOut>
          Log Out
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>

</template>

<script>
import UserStreamingService from "../services/user-streaming.service";
import config from '@/config.json'
import userService from "../services/user.service";
import {mapGetters} from "vuex";

export default {
  name: "AppleMusic",
  props: {
    showDialog: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showComplete: false,
    authCode:null
  }),
  computed: {

    ...mapGetters(
      {
        currentUser: 'auth/getCurrentUser'
      },
    ),
  },
  methods: {
    authorizeSpotify() {
			const clientId = config.clientId;
			const redirectUri = `${process.env.VUE_APP_BASE_URL}/apple-music`;; // This is the redirect URI should be dynamic
			const scopes = 'user-read-email user-read-private streaming user-modify-playback-state user-read-playback-state';
			const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirectUri)}`;
			window.location = authUrl;
		},
    async handleSpotifyCallback() {
		
    const redirectUri = `${process.env.VUE_APP_BASE_URL}/apple-music`;
    if (!this.authCode) return;
    try {
      const authCode = this.authCode;
      const response = await this.$axios.post('/spotify/swap', { authCode ,redirectUri});
      const spotifyAccessToken = response.data.access_token;
      const spotifyRefreshToken = response.data.refresh_token;

      this.setSpotifyToken(spotifyAccessToken, spotifyRefreshToken);
      this.spotifyAuthorize(spotifyRefreshToken);
      window.history.pushState({}, document.title, "/dashboard");
    } catch (error) {
      console.error("Failed to swap authorization code for token:", error);
    }
  },
  setSpotifyToken(token,refreshToken) {
          this.$store.dispatch('updateSpotifyToken', token);
          this.$store.dispatch('updateSpotifyRefreshToken', refreshToken); // Set token in Vuex
      },

  async spotifyAuthorize(token) {
    try {

      const data = {
        "user_id": this.$store.getters['auth/getCurrentUser'].id,
        "service_id": 2,
        "token": token,
        "store_front": 'us' // should be changed
      }

      await UserStreamingService.authorize(data).then(() => {
        userService.updateUserStreamingServie(this.$store.getters['auth/getCurrentUser'].id)
        .then((data) => {	
          this.currentUser.current_music_streaming_service = data.current_music_streaming_service ;

          this.$store.dispatch('showSnackbarMessage', {
            message: 'Spotify account is linked successfully.',
            duration: 8000,
            mode: 'success'
          });
					})
					.catch((err) => {	
						this.$store.dispatch('showSnackbarMessage',
							{
								message: `${err.message}. Please, try again.`,
								mode: 'fail'
							});
					});
        this.$router.push('/dashboard');

      }).catch(() => {
        this.$store.dispatch('showSnackbarMessage', {
          message: 'Failed. Please, try again.',
          duration: 8000,
          mode: 'fail'
        });
      });

    } catch (e) {
      console.log('error', e)
      await this.$store.dispatch('showSnackbarMessage', {
        message: `${e.message}. Please, try again.`,
        duration: 4000,
        mode: 'fail'
      });
    }
  },
    setPageName() {
      this.$store.dispatch('changePageName', {pageNameString: 'Music Authorization'});
    },
    logOut() {
      this.$store.dispatch('auth/signOut').then(() => {

        this.$store.dispatch('audioPlayer/updateSongToPlayFromBluePrint', {song: null, name: null});
        this.$router.replace('/login');
      });
    },

    async authorize() {
      const music = await MusicKit.getInstance()

      try {
        await music.authorize();
        const data = {
          "user_id": this.$store.getters['auth/getCurrentUser'].id,
          "service_id": 1,
          "token": music.musicUserToken,
          "store_front": music.storefrontId
        }

        await UserStreamingService.authorize(data).then(() => {
          this.$store.dispatch('showSnackbarMessage', {
            message: 'Apple account is linked successfully.',
            duration: 8000,
            mode: 'success'
          });
          this.$router.push('/dashboard');

        }).catch(() => {
          this.$store.dispatch('showSnackbarMessage', {
            message: 'Failed. Please, try again.',
            duration: 8000,
            mode: 'fail'
          });
        });

      } catch (e) {
        console.log('error', e)
        await this.$store.dispatch('showSnackbarMessage', {
          message: `${e.message}. Please, try again.`,
          duration: 4000,
          mode: 'fail'
        });
      }
    },
  },

  created() {
    this.setPageName();
    const query = new URLSearchParams(window.location.search);
			 this.authCode = query.get('code');
			
			if (this.authCode) {
				this.handleSpotifyCallback();
			} 
  }
}
</script>

<style lang="scss" scoped>
@include dialogSettings();
.md-dialog-container {
  transform: translate(0%, 0%) scale(1) !important;
}
.music-icon{
  width: 25px;
  height: 25px;
  margin-right:5px;
}
.centerize{
  color: #fff!important;
  @include respond(xsmall) {
    width: 60%!important;
    padding: 0!important;
    margin: 0!important;
  }
}
.md-dialog {

  @include respond(small) {
    align-self: center;
    height: 300px;
  }

  @media screen and (max-width: 980px) and (min-width: 700px) {
    margin-top: 80px;
  }

  /*width: auto !important;*/
  padding-left: 1.5em;
  padding-right: 1.5em;
  text-align: center;

  .plan-form {
    text-align: center;
  }

  .md-dialog-actions {
    justify-content: center;
    display: flex;
    @include respond(xsmall) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

  }

  .list-group {

    .current-subscriptions {

      @include respond(small) {
        margin-top: 30px;
      }

      @media screen and (max-width: 980px) and (min-width: 700px) {
        margin-top: 30px;
      }
    }
  }
}
</style>
