import UserService from "../../services/user.service";

export default async function appleMusicCheck({to, next, store, nextMiddleware}) {

    const user = await UserService.getOne(store.getters['auth/getCurrentUser'].id)
    const pathAppleMusic = '/apple-music';
    const music = await MusicKit.getInstance();
    
    music.musicUserToken = user.services.length !== 0 ? user.services.find(sr => sr.service_id == 1)?.token: '';
    const spotifyMusicUserToken = user.services.length !== 0 ? user.services.find(sr => sr.service_id == 2)?.token: null;
    if (!music.isAuthorized && !spotifyMusicUserToken) {
        return next({
            path: pathAppleMusic,
        });
    }


    return nextMiddleware();
}
