import helpers from "../store/helpers";
export const MusicKitHelperMixin = {
    data: () => ({
        music: null
    }),
    methods: {
        async closeAppleAudioPlayer(musicService) {
            if (musicService == 1){

                await this.music.stop();
                await this.music.clearQueue();
            }
            if (musicService == 2){
                const player = await this.$store.getters.spotifyPlayer;
                if (player) {
                    await this.$store.dispatch('pause',0);
                    await this.$store.dispatch('seek',0);
                }
            }
            await this.$store.dispatch('audioPlayer/resetPlayerData');
        },
        getUrl(artwork, size) {
            const fakeArtwork =
                'https://is1-ssl.mzstatic.com/image/thumb/Features127/v4/75/f9/6f/75f96fa5-99ca-0854-3aae-8f76f5cb7fb5/source/200x200bb.jpeg';
            return helpers.getSafe(
                () => MusicKit.formatArtworkURL(artwork, size, size),
                fakeArtwork,
            );
        },
        async getArtWork(trackStreamingID, size) {
            const searchResult = await this.music.api.music(`/v1/catalog/us/songs?ids=${trackStreamingID}`);
            if (searchResult.data.data[0]&& searchResult.data.data.length > 0)
                return this.getUrl(searchResult.data.data[0].attributes.artwork, size);
        }
    },
    created() {
        this.music = MusicKit.getInstance();
    },

}